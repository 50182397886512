import './App.css';
import shib from './shib.png';
import Topper from './topper';


function App() {

  return (
    <div className="App">
      <header className="App-header">
        <div className="branding-logo">
          <Topper />
        </div>
      </header>

      <div className="Welcome-new-main">
          <p>find your flaws before attackers do</p>
        </div>

      <img src={shib} width={320} height={320} alt="shib" />;

      <div className="Welcome">
        <p></p>
      </div>

      <div className="App-body">
      </div>

      <div className="Bottom">
        <p>X Github Medium ⚡️ All Rights Reserved 2024</p>

      </div>
    </div>




  );
}

export default App;
