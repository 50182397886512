import React from 'react'
import ContactForm from "./ContactForm";
import Topper from '../topper';

function Contact() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="branding-logo">
        <Topper />
        </div>
      </header>

      <div className="Welcome-new-main">
          <p>contact us using the form below</p>
      </div>

      <div className='team-text'>
        <p>Find out how our modern SaaS platform and community of trusted security experts can help you stay ahead of evolving threats. </p>
      </div>

      <div className="App-body-form">
      <ContactForm />
      </div>

      <div className="Bottom">
        <p>X Github Medium ⚡️ All Rights Reserved 2024</p>
      </div>
    </div>
  )
}

export default Contact