import React, { useState } from "react";
import styled from 'styled-components';

const FORM_ENDPOINT = "https://public.herotofu.com/v1/3caf8720-9be0-11ee-9dec-893a2542e245";

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: #040e21;
  padding: 20px;
  box-sizing: border-box;
`;

const HeroForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #040e21;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;

`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: #007BFF;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  resize: none;
  height: 100px;
  &:focus {
    outline: none;
    border-color: #007BFF;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: #040e21;
  color: #fff;
  font-size: 16px;
  font-family: 'Saira', sans-serif;
  cursor: pointer;
  &:hover {
    background-color: #0f1e3b;
  }
`;

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);

  if (submitted) {
    return (
      <div className="Welcome-new-main">
        <p>Thank you! We'll be in contact soon.</p>
      </div>
    );
  }

  return (
    <HeroContainer>
      <form
        action={FORM_ENDPOINT}
        method="POST"
        target="_self"
        onSubmit={() => setSubmitted(true)}
      >
        <HeroForm>
          <Input type="text" placeholder="Name" name="name" required />
          <Input type="email" placeholder="Email" name="email" required />
          <Textarea placeholder="Share some details" name="message" required></Textarea>
          <Button type="submit">Send</Button>
        </HeroForm>
      </form>
    </HeroContainer>
  );
};

export default ContactForm;
