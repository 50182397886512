import React from 'react'
import Topper from '../topper';

function Price() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="branding-logo">
          <Topper />
        </div>
      </header>

      <div className="Welcome-new-main">
        <p>current security offerings</p>
      </div>

      <div className='price-container'>

        <div className='price-link-border-one'>
          <p>Security Assessment</p>
        </div>

        <div className='price-link-border-two'>
          <p>Threat Hunt</p>
          <p>Penetration Test</p>
          <p>Forensic Investigation</p>
        </div>

        <div className='price-link-border-three'>
          <p>Design & Architecture</p>
        </div>

      </div>

      <div className="App-body">
      </div>

      <div className="Bottom">
        <p>X Github Medium ⚡️ All Rights Reserved 2024</p>

      </div>
    </div>

  )
}

export default Price